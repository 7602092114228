import * as React from "react";
import { Link } from "gatsby";

import '../styles/components/_old2005blogpage.scss';

const Disclaimer = () => (
	<div className="Old2005BlogPageDisclaimer">
		<h2>Disclaimer</h2>
		<p>
			I started blogging with Blogger in 2005 and slowed down as I reached my blogging end in 2010.
			I have attempted to save all the blog entries in monthly archive pages.
			It's quite interesting to go back and see my novice, naive enthusiasm for music making and playing, as well as for travelling.
			I also notice that I used a lot of exclamation marks! I must have been excited.
			For a while, anyway... I note that I slowed down quite a bit from 2008 onward;
			the momentum of my first batch of songs—written, released, and toured—had worn off.
			Also, I was amidst my first real bout of homesickness—I was living in New Zealand at the time.
		</p>
		<p className="card">
			<span className="card">
				A couple things to note.
				Some of my spelling is American rather than British/Canadian (I'm Canadian).
				Regrettably, I note that I used the word "tits" a lot—for a while—without being conscious of
				how senseless, unnecessary, and thoughtless doing so was.
				Please take what you read with these grains of salt.<br />
				<Link to="/blog2005/">Return to the root/index of the blog</Link>.
			</span>

		</p>
	</div>
);

const Old2005BlogPage = (props) => (
	<div className="Old2005BlogPage">
		<Disclaimer />
		{props.children}
	</div>
);

const doNothingAnchor = e => e.preventDefault();

export {Disclaimer, doNothingAnchor};
export default Old2005BlogPage;
